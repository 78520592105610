/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "../../index.css";
import { DownloadOutlined } from "@mui/icons-material";
import { handleExportToExcel } from "../support/excelExport";

const OverviewModal = () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState("");
  const [dataStatus, setDataStatus] = useState("");

  useEffect(() => {
    fetchData();
  }, [date]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://skv-testing.moiaccount.in/testapi/orders/getOrdersForOverView",
        {
          created_date: date,
        }
      );

      if (response.data.mStatus === 200) {
        setData(response.data.mData);
        setDataStatus(""); // Reset the status if data is found
      } else if (response.data.mStatus === 202) {
        setData([]);
        setDataStatus("No Data Found");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl. No",
      width: 80,
      headerClassName: "customHeader",
      renderCell: (params) => {
        return params.row.slno;
      },
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "contact_person_name",
      headerName: "Customer Name",
      width: 180,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "description",
      headerName: "Ordered Product",
      width: 140,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 110,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "wages",
      headerName: "Wages",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "weight",
      headerName: "Weights",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "order_date",
      headerName: "Order Date",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString(); // Format the date as a string
      },
    },
    {
      field: "created_at",
      headerName: "Created Date",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString(); // Format the date as a string
      },
    },
  ];

  const exportExcel = () => {
    handleExportToExcel(data, "OverView", "overview");
  };

  const getRowClassName = (params) => {
    return "customRow"; // Return the class name for row styling
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      <TextField
        label="To Date"
        type="date"
        variant="outlined"
        fullWidth
        value={date}
        onChange={handleDate}
        sx={{ width: "200px" }}
        style={{ marginBottom: 20 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        sx={{ height: "50px" }}
        startIcon={<DownloadOutlined />}
        onClick={exportExcel}
      >
        Export to Excel
      </Button>
      <DataGrid
        rows={data.map((data, index) => ({ ...data, slno: index + 1 }))}
        columns={columns}
        getRowClassName={getRowClassName}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.order_id}
        components={{
          NoRowsOverlay: () => (
            <Typography variant="h6" align="center" sx={{ mt: 2 }}>
              {dataStatus || "No Rows"}
            </Typography>
          ),
        }}
      />
    </Box>
  );
};

export default OverviewModal;
