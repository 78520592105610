/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
//import "../../data.css";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select, // Added Select component
  MenuItem, // Added MenuItem component
  FormControl, // Added FormControl component
  InputLabel, // Added InputLabel component
  useTheme,
  Checkbox,
} from "@mui/material";
import { tokens } from "../../theme";
import Swal from "sweetalert2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/lab/Autocomplete";
import "../../index.css";
import { changeReviewedStsAlert, editOptionAlert } from "../support/sweetAlert";
import { handleSKV80OrdersDelete } from "../support/deleteData";
import { generateReceipt } from "../support/receiptGeneration";
import { fetchCustomerOptionData } from "../support/customerData";
import { DownloadDoneOutlined } from "@mui/icons-material";
import { handleExportToExcel } from "../support/excelExport";
import { fetchSKV80OrdersData } from "../support/fetchOrdersData";
import { insertedBy, isReviewed } from "../support/sessionUserName";

export default function Skv80OrdersTable({ orders }) {
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState(null); // State variable for search value
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [editedQuantity, setEditedQuantity] = useState("");
  const [editedWages, setEditedWages] = useState("");
  const [editedWeight, setEditedWeight] = useState("");
  const [editedOrderDate, setEditedOrderDate] = useState("");
  const [editedStatus, setEditedStatus] = useState("");
  const [editedOrderStatus, setEditedOrderStatus] = useState(null);
  const [errorDescription] = useState("");
  const [errorWeight, setErrorWeight] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [error, setError] = useState("");
  const [statusError, setErrorStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [orderStatusDialogOpen, setOrderStatusDialogOpen] = useState(false);

  const [otherDescription, setOtherDescription] = useState("");
  const [showOtherTextField, setShowOtherTextField] = useState(false);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value === "other") {
      setShowOtherTextField(true);
      // setNewCustomer({ ...newCustomer, description: otherDescription });
    } else {
      setShowOtherTextField(false);
      setNewCustomer({ ...newCustomer, description: value });
    }
  };

  const handleReviewStatusChange = async (event, row) => {
    if (sessionStorage.getItem("userType") === "1") {
      const newValue = event.target.checked ? 1 : 0;
      try {
        const response = await axios.post(
          `https://skv-testing.moiaccount.in/testapi/orders/updateStatus`,
          {
            orderId: row.order_id,
            is_reviewed: newValue,
          }
        );
        if (response.data.mStatus === 200) {
          alert("Status updated successfully");
          fetchSKV80OrdersData(setRows, orders);
        } else {
          alert("Error updating status");
        }
      } catch (error) {
        console.error("Error updating review status:", error);
      }
    } else {
      alert("Access restricted!");
    }
  };

  const handleOrderStatusChangeChecked = async (event, row) => {
    if (row.is_reviewed === 1) {
      const newValue = event.target.checked ? 2 : 0;
      Swal.fire({
        title: "Are you sure?",
        text: "You Want To Change The Order Status",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.post(
              "https://skv-testing.moiaccount.in/testapi/orders/updateOrderStatus",
              {
                orderId: row.order_id,
                order_status: newValue,
              }
            );
            if (response.data.mStatus === 200) {
              Swal.fire({
                title: "Success",
                text: "Order Status Changed",
                icon: "success",
              });
              // Refresh data if needed
              fetchSKV80OrdersData(setRows, orders);
            } else {
              // If deletion is not successful
              Swal.fire({
                title: "Error!",
                text: "No Response Comes From Backend",
                icon: "error",
              });
            }
          } catch (error) {
            // If an error occurs during deletion
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
            });
          }
        }
      });
    } else {
      //alert("Kindly Change Reviewed Status");
      changeReviewedStsAlert();
    }
  };

  const handleOrderStatusClick = (row) => {
    if (sessionStorage.getItem("userType") === "1") {
      if (row.is_reviewed === 1) {
        setEditedRow(row);
        setEditedOrderStatus(row.order_status === 2 ? "Closed" : "Pending");
        setOrderStatusDialogOpen(true);
      } else {
        //alert("Please change the status of the review first.");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Change Is Reviewed Status To Approved",
        });
      }
    } else {
      if (row.is_reviewed === 1) {
        setEditedRow(row);
        // eslint-disable-next-line default-case
        switch (row.order_status) {
          case 0:
            setEditedOrderStatus("Pending");
            break;
          case 1:
            setEditedOrderStatus("InProgress");
            break;
          case 2:
            setEditedOrderStatus("Closed");

            break;
        }
        setOrderStatusDialogOpen(true);
      } else {
        //  alert("Please Change the status of the review first.")
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Change Is Reviewed Status To Approved",
        });
      }
    }
  };

  const handleOrderStatus = async () => {
    try {
      if (
        editedOrderStatus === "Pending" ||
        editedOrderStatus === "Closed" ||
        editedOrderStatus === "InProgress"
      ) {
        setError("Please select the order status");
        return;
      }
      // Construct the payload with order_id and editedStatus
      const payload = {
        orderId: editedRow.order_id,
        //order_status: editedOrderStatus === 1 ? 1 : 0,
        order_status: editedOrderStatus,
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://skv-testing.moiaccount.in/testapi/orders/updateOrderStatus",
        payload
      );

      // Handle the response
      if (response.data.mStatus === 200) {
        alert("Order Status updated successfully");
        fetchSKV80OrdersData(setRows, orders); // Refresh data if needed
      }

      if (response.data.mStatus === 402) {
        alert("Error While Updating Status Of Order");
      }

      // Close the status dialog
      setOrderStatusDialogOpen(false);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const [newCustomer, setNewCustomer] = useState({
    description: "",
    quantity: "",
    wages: "",
    weight: "",
    customer_id: "",
    inserted_by: insertedBy(),
    order_status: 0,
    is_reviewed: isReviewed(),
    order_date: new Date().toISOString().split("T")[0], // Initialize with today's date
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleEditOpen = (row) => {
    // eslint-disable-next-line eqeqeq
    if (sessionStorage.getItem("userType") == "1") {
      setEditedRow(row);
      setEditedDescription(row.description);
      setEditedQuantity(row.quantity);
      setEditedWages(row.wages);
      setEditedWeight(row.weight);
      setEditedStatus(row.is_reviewed);
      setEditedOrderDate(row.order_date);
      setEditOpen(true);
    } else {
      // eslint-disable-next-line eqeqeq
      if (row.inserted_by == insertedBy()) {
        setEditedRow(row);
        setEditedDescription(row.description);
        setEditedQuantity(row.quantity);
        setEditedWages(row.wages);
        setEditedWeight(row.weight);
        setEditedStatus(row.is_reviewed);
        setEditedOrderDate(row.order_date);
        setEditOpen(true);
      } else {
        /** No Edit Option Alert */
        editOptionAlert();
      }
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const getRowClassName = (params) => {
    return "customRow"; // Return the class name for row styling
  };

  useEffect(() => {
    // fetchSKV80OrdersData(setRows, orders);
    fetchSKV80OrdersData(setRows, orders);
  }, [setRows, orders]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  // const filteredRows = rows.filter((row) =>
  //   row.contact_person_name.toLowerCase().includes(searchValue.toLowerCase())
  // );
  const handleOrderStatusChange = (event, newValue) => {
    setOrderStatus(newValue);
  };

  const filterRows = () => {
    const searchString = searchValue ? searchValue.contact_person_name : "";

    let filteredRows = rows.filter(
      (row) => row.contact_person_name === searchString
    );

    filteredRows = searchString !== "" ? filteredRows : rows;
    // Filter rows based on order status
    if (orderStatus !== null) {
      filteredRows = filteredRows.filter(
        (row) => row.is_reviewed === (orderStatus === "Approved" ? 1 : 0)
      );
    }

    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate + "T00:00:00");
      const toDateObj = new Date(toDate + "T23:59:59");

      filteredRows = filteredRows.filter((row) => {
        const orderDate = new Date(row.order_date);
        return orderDate >= fromDateObj && orderDate <= toDateObj;
      });
    }

    return filteredRows;
  };

  const handleEditSubmit = async () => {
    try {
      // Construct the updated order object including order_id
      const updatedOrder = {
        order_date: editedOrderDate,
        order_id: editedRow.order_id,
        description: editedDescription,
        quantity: editedQuantity,
        wages: editedWages,
        weight: editedWeight,
      };

      // Update the row with the edited data
      const updatedRows = rows.map((row) => {
        if (row.order_id === editedRow.order_id) {
          return {
            ...row,
            order_date: editedOrderDate,
            description: editedDescription,
            quantity: editedQuantity,
            wages: editedWages,
            weight: editedWeight,
          };
        }
        return row;
      });
      setRows(updatedRows);

      // Send updated data to API
      const response = await axios.post(
        `https://skv-testing.moiaccount.in/testapi/orders/updateOrders`,
        //`https://skv-testing.moiaccount.in/testapi/orders/updateOrders`,
        updatedOrder
      );

      // Handle success or failure
      if (response.data.mStatus === 200) {
        alert("Order updated successfully");
      } else {
        alert("Failed to update order");
      }

      handleEditClose();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl. No",
      width: 60,
      headerClassName: "customHeader",
      renderCell: (params) => {
        return params.row.slno;
      },
    },
    {
      field: "order_id",
      headerName: "Order ID",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "contact_person_name",
      headerName: "Customer Name",
      width: 160,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "purity",
      headerName: "Group",
      width: 110,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "description",
      headerName: "Ordered Product",
      width: 150,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "weight",
      headerName: "Weights",
      width: 110,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "order_status",
      headerName: "Order Status",
      headerClassName: "customHeader",
      cellClassName: "customCell",
      width: 130,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.order_status === 2}
          onChange={(event) =>
            handleOrderStatusChangeChecked(event, params.row)
          }
          color="primary"
        />
      ),
    },
    {
      field: "is_reviewed",
      headerName: "Is Reviewed",
      headerClassName: "customHeader",
      cellClassName: "customCell",
      width: 130,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.is_reviewed === 1}
          onChange={(event) => handleReviewStatusChange(event, params.row)}
          color="primary"
        />
      ),
    },
    {
      field: "order_date",
      headerName: "Order Date",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      valueFormatter: (params) => {
        // Extract the date part from the ISO string
        const date = new Date(params.value);
        return date.toLocaleDateString(); // Format the date as a string
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 90,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "wages",
      headerName: "Wages",
      width: 100,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "Action",
      headerName: "Action",
      width: 180,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return (
          <div>
            {/* Replace these with your desired icons */}
            <BorderColorIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleEditOpen(params.row)}
            />
            <DeleteIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() =>
                handleSKV80OrdersDelete(
                  rows,
                  params.row.order_id,
                  fetchSKV80OrdersData(setRows, orders)
                )
              }
            />
          </div>
        );
      },
    },
  ];

  const handleStatusUpdate = async () => {
    try {
      if (editedStatus === "Pending" || editedStatus === "Approved") {
        setErrorStatus("Please Select The Status");
        return;
      }
      // Construct the payload with order_id and editedStatus
      const payload = {
        orderId: editedRow.order_id,
        //is_reviewed: editedStatus == "Approved" ? 1 : 0, // Assuming 1 for Approved and 0 for Pending
        is_reviewed: editedStatus === 1 ? 1 : 0,
      };

      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://skv-testing.moiaccount.in/testapi/orders/updateStatus",
        payload
      );

      // Handle the response
      if (response.data.mStatus === 200) {
        alert("Status updated successfully");
        fetchSKV80OrdersData(setRows, orders); // Refresh data if needed
      }

      if (response.data.mStatus === 402) {
        alert("Error While Updating Status Of Order");
      }
      setErrorStatus("");
      // Close the status dialog
      setStatusDialogOpen(false);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Define columns based on user type
  let column = [...columns];

  useEffect(() => {
    fetchCustomerOptionData("skv80", setCustomerOptions); // Fetch customer data on component mount
  }, [setCustomerOptions]);

  const handleCancel = () => {
    setOrderStatusDialogOpen(false);
    setError("");
  };

  const handleStatusCancel = () => {
    setStatusDialogOpen(false);
    setErrorStatus("");
  };

  const receiptGeneration = async () => {
    try {
      const result = await generateReceipt("SKV-80", filterRows);
    } catch (error) {
      console.error(error.message);
    }
  };

  const excelExport = () => {
    handleExportToExcel(rows, "SKV80-orders", "skv80");
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Autocomplete
          id="customer-search"
          options={customerOptions}
          getOptionLabel={(option) => option.contact_person_name}
          value={searchValue}
          onChange={(event, newValue) => {
            setSearchValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "200px" }}
              label="Search by Customer Name"
            />
          )}
        />
        <Autocomplete
          id="order-status-search"
          options={["Approved", "Pending"]}
          value={orderStatus}
          onChange={handleOrderStatusChange}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "200px" }}
              label="Search by Reviewed Status"
            />
          )}
        />
        <TextField
          label="From Date"
          type="date"
          variant="outlined"
          fullWidth
          value={fromDate}
          onChange={handleFromDateChange}
          sx={{ width: "200px" }}
          style={{ marginBottom: 20 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="To Date"
          type="date"
          variant="outlined"
          fullWidth
          value={toDate}
          onChange={handleToDateChange}
          sx={{ width: "200px" }}
          style={{ marginBottom: 20 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          style={{ height: "50px" }}
          variant="contained"
          color="primary"
          onClick={receiptGeneration}
        >
          Print Receipt
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ height: "50px" }}
          startIcon={<DownloadDoneOutlined />}
          onClick={excelExport}
        >
          Export to Excel
        </Button>
      </div>
      {/* Is Reviewed  Status Update Dialog */}

      <Dialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <FormControl
            margin="dense"
            rol
            fullWidth={false}
            style={{ width: "300px" }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <MenuItem value={1}>Approved</MenuItem>
              <MenuItem value={2}>Pending</MenuItem>
            </Select>
          </FormControl>
          {statusError && <p style={{ color: "red" }}>{statusError}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusCancel}>Cancel</Button>
          <Button onClick={handleStatusUpdate}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Order Status Update Dialog */}
      {sessionStorage.getItem("userType") === "1" ? (
        <Dialog
          open={orderStatusDialogOpen}
          onClose={() => setOrderStatusDialogOpen(false)}
        >
          <DialogTitle>Update Order Status</DialogTitle>
          <DialogContent>
            <FormControl
              margin="dense"
              rol
              fullWidth={false}
              style={{ width: "300px" }}
            >
              <InputLabel>Order Status</InputLabel>
              <Select
                value={editedOrderStatus === null ? "" : editedOrderStatus}
                onChange={(e) =>
                  setEditedOrderStatus(
                    e.target.value === "" ? null : e.target.value
                  )
                }
              >
                <MenuItem value={2}>Closed</MenuItem>
                <MenuItem value={0}>Pending</MenuItem>
              </Select>
            </FormControl>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOrderStatus}>Save</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={orderStatusDialogOpen}
          onClose={() => setOrderStatusDialogOpen(false)}
        >
          <DialogTitle>Update Order Status</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="dense">
              <InputLabel>Order Status</InputLabel>
              <Select
                // eslint-disable-next-line eqeqeq
                value={editedOrderStatus == 0 ? "Pending" : "InProgress"}
                onChange={(e) => setEditedOrderStatus(e.target.value)}
              >
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>InProgress</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOrderStatus}>Save</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Edit popup component */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Orders</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Order Date"
            type="date"
            fullWidth
            required
            name="order_date"
            value={editedOrderDate}
            onChange={(e) => setEditedOrderDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Select
            name="description"
            label="Description"
            value={editedDescription}
            required
            fullWidth
            margin="dense"
            onChange={(e) => setEditedDescription(e.target.value)}
          >
            <MenuItem value="M">METTI(M)</MenuItem>
            <MenuItem value="KA">KAAPU(KA)</MenuItem>
            <MenuItem value="K">KODI(K)</MenuItem>
            <MenuItem value="70">70</MenuItem>
            <MenuItem value="70-Kolusu">70-Kolusu</MenuItem>
            <MenuItem value="80">80</MenuItem>
            <MenuItem value="80-Kolusu">80-Kolusu</MenuItem>
            <MenuItem value="T">THANDAI(TH)</MenuItem>
            <MenuItem value="NT">NT</MenuItem>
            <MenuItem value="WS">WS</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
          <TextField
            margin="dense"
            label="Quantity"
            fullWidth
            name="quantity"
            value={editedQuantity}
            onChange={(e) => setEditedQuantity(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Wages"
            fullWidth
            required
            name="wages"
            value={editedWages}
            onChange={(e) => setEditedWages(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Weight"
            fullWidth
            required
            name="weight"
            value={editedWeight}
            onChange={(e) => setEditedWeight(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        rows={filterRows().map((row, index) => ({ ...row, slno: index + 1 }))}
        columns={column}
        getRowClassName={getRowClassName} // Apply row styling
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.order_id}
      />
    </Box>
  );
}
