import { Box, Checkbox } from "@mui/material";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ApprovalPieChart from "../../components/ApprovalPieChart";

const Pie = () => {
  const [skv5Pending, setSkv5Pending] = useState(0);
  const [skv5Closed, setSkv5Closed] = useState(0);
  const [skv5Approved, setSkv5Approved] = useState(0);
  const [skv5NotApproved, setSkv5NotApproved] = useState(0);
  const [skv5Total, setSkv5Total] = useState(0);

  const [skv80Pending, setSkv80Pending] = useState(0);
  const [skv80Closed, setSkv80Closed] = useState(0);
  const [skv80Approved, setSkv80Approved] = useState(0);
  const [skv80NotApproved, setSkv80NotApproved] = useState(0);
  const [skv80Total, setSkv80Total] = useState(0);
  const [checkBoxClicked, setCheckBoxClicked] = useState(false);

  const fetchPieChartData = async () => {
    try {
      const response = await axios.get(
        "https://skv-testing.moiaccount.in/testapi/orders/orderChartData"
      );
      setSkv5Total(response.data.mData.skv5Details.total);
      setSkv5Pending(response.data.mData.skv5Details.pending);
      setSkv5Closed(response.data.mData.skv5Details.closed);
      setSkv5Approved(response.data.mData.skv5Details.approved);
      setSkv5NotApproved(response.data.mData.skv5Details.notApproved);

      setSkv80Total(response.data.mData.skv80Details.total);
      setSkv80Pending(response.data.mData.skv80Details.pending);
      setSkv80Closed(response.data.mData.skv80Details.closed);
      setSkv80Approved(response.data.mData.skv80Details.approved);
      setSkv80NotApproved(response.data.mData.skv80Details.notApproved);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchPieChartData();
  }, []);

  const title = !checkBoxClicked
    ? `Total Orders - ${skv5Total}`
    : `Total Orders - ${skv80Total}`;
  const headerTitle = !checkBoxClicked
    ? "SKV-5 Details Chart"
    : "SKV-80 Details Chart";
  return (
    <Box m="20px">
      <Header title={headerTitle} subtitle={title} />
      <div style={{ display: "flex" }}>
        <Checkbox
          value={true}
          onChange={(event) => setCheckBoxClicked(event.target.checked)}
        />
        <p>SKV-80</p>
      </div>
      {!checkBoxClicked && (
        <Box display="flex" height="50vh">
          <PieChart pending={skv5Pending} closed={skv5Closed} />
          <ApprovalPieChart
            approved={skv5Approved}
            notApproved={skv5NotApproved}
          />
        </Box>
      )}
      {checkBoxClicked && (
        <Box display="flex" height="50vh">
          <PieChart pending={skv80Pending} closed={skv80Closed} />
          <ApprovalPieChart
            approved={skv80Approved}
            notApproved={skv80NotApproved}
          />
        </Box>
      )}
    </Box>
  );
};

export default Pie;
