import {
  Box,
  Button,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { tokens } from "../../theme";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import PieActiveArc from "./PieMuiCharts";
import CustomerGroupBarChart from "../../components/CustomerGroupBarChart";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import AppWidgetSummary, { formatDate } from "../support/encryption";
import Container from "@mui/material/Container";

const Dashboard = () => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [openModalGold, setOpenModalGold] = useState(false);
  const [errorGold, setErrorGold] = useState("");
  const [errorSilver, setErrorSilver] = useState("");
  const colors = tokens(theme.palette.mode);
  const CustNumber = localStorage.getItem("lenOfCust");
  const CustPer = 0 + CustNumber;
  const OrderNumber = localStorage.getItem("lenOfOrders");
  const OrderPer = 0 + OrderNumber;
  const UserNumber = localStorage.getItem("lenOfUsers");
  const UserPer = 0 + UserNumber;
  const [goldRate, setGoldRate] = useState("");
  const [silverRate, setSilverRate] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [newGoldRate, setNewGoldRate] = useState({
    gold_rate_per_gm: "",
    silver_rate_per_gm: "",
    inserted_by: 1,
  });
  const [goldRateDate, setGoldRateDate] = useState("");
  const [details, setDetails] = useState([]);

  const lastGoldRateDate = (date) => {
    setGoldRateDate(formatDate(date));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoldRate({ ...newGoldRate, [name]: value });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorGold("");
    setErrorSilver("");
  };

  const handleCloseModalGold = () => {
    setOpenModalGold(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalGold = () => {
    setOpenModalGold(true);
  };

  const fetchGoldRate = async () => {
    try {
      const goldRate = await axios.get(
        "https://skv-testing.moiaccount.in/testapi/goldRate/getGoldRate"
      );
      if (goldRate) {
        setGoldRate(goldRate.data.mData[0].gold_rate_per_gm);
        setSilverRate(goldRate.data.mData[0].silver_rate_per_gm);
        lastGoldRateDate(goldRate.data.mData[0].created_at);
        setDetails(goldRate.data.mData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGoldRate();
  }, []);

  const handleAddGoldRate = async () => {
    if (!newGoldRate.gold_rate_per_gm || !newGoldRate.silver_rate_per_gm) {
      if (newGoldRate.gold_rate_per_gm === "") {
        setErrorGold("Please Enter The Gold Rate");
      }
      if (newGoldRate.silver_rate_per_gm === "") {
        setErrorSilver("Please Enter The Silver Rate");
      }
    } else {
      try {
        const addResponse = await axios.post(
          `https://skv-testing.moiaccount.in/testapi/goldRate/addGoldRate`,
          newGoldRate
        );
        if (addResponse.data.mStatus === 400) {
          alert(addResponse.data.mData.error);
        }
        if (addResponse.data.mStatus === 200) {
          alert("Gold Rate Data Added Successfully");
          fetchGoldRate();
        }
        handleCloseModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchOrderData = async () => {
    try {
      const response = await axios.get(
        `https://skv-testing.moiaccount.in/testapi/orders/getOrders`
      );
      if (response.data.mData) {
        const filterData = response.data.mData.filter(
          (items) => items.is_delete !== 1
        );
        setOrderData(filterData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const userType = () => {
    return sessionStorage.getItem("userType");
  };

  const ScrollingText = styled("div")({
    display: "inline-block",
    whiteSpace: "nowrap",
    animation: "scroll 10s linear infinite",
    "@keyframes scroll": {
      "0%": {
        transform: "translateX(100%)",
      },
      "100%": {
        transform: "translateX(-100%)",
      },
    },
  });

  return (
    // <Box m="20px">
    <Container maxWidth="xl">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Box>
          <div
            sx={{
              backgroundColor: "white",
              color: "black",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <ScrollingText>
              {" "}
              Todays Gold Rate -{" "}
              <strong style={{ backgroundColor: "black", color: "white" }}>
                {goldRate}
              </strong>
            </ScrollingText>
          </div>
        </Box>
        <Box>
          <div
            sx={{
              backgroundColor: "white",
              color: "black",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <ScrollingText>
              Todays Silver Rate -{" "}
              <strong style={{ backgroundColor: "black", color: "white" }}>
                {silverRate}
              </strong>
            </ScrollingText>
          </div>
        </Box>
        <Box>
          <Button
            onClick={handleOpenModal}
            startIcon={<FileUploadIcon />}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Todays Gold Rate
          </Button>
        </Box>
      </Box>

      {userType() === "1" && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardHeader
                title="Customers Summary"
                avatar={
                  <PersonAddIcon sx={{ color: colors.greenAccent[600] }} />
                }
              />
              <CardContent>
                <Typography
                  variant="h5"
                  style={{ fontStyle: "bold" }}
                  component="div"
                >
                  {CustNumber}
                </Typography>
                <Typography
                  variant="h5"
                  style={{ fontStyle: "bold" }}
                  color="grey"
                >
                  CUSTOMERS
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardHeader
                title="Orders Summary"
                avatar={
                  <PointOfSaleIcon sx={{ color: colors.greenAccent[600] }} />
                }
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {OrderNumber}
                </Typography>
                <Typography variant="h5" color="grey">
                  ORDERS
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardHeader
                title="Users Summary"
                avatar={
                  <PersonAddIcon sx={{ color: colors.greenAccent[600] }} />
                }
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {UserNumber}
                </Typography>
                <Typography variant="h5" color="grey">
                  USERS
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardHeader
                onClick={handleOpenModalGold}
                title="Gold Rate Details - click here"
                avatar={
                  <PersonAddIcon sx={{ color: colors.greenAccent[600] }} />
                }
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {goldRateDate}
                </Typography>
                <Typography variant="h5" color="grey">
                  <div style={{ display: "flex" }}>LAST UPDATED DATE</div>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader title="O R D E R S - C O U N T : SKV-5, SKV-80, GST" />
              <CardContent sx={{ height: "300px", overflow: "auto" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <PieActiveArc />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card style={{ height: "350px", overflow: "auto" }}>
              <CardHeader title="R E C E N T -  O R D E R S" />
              <CardContent>
                {orderData.map((transaction, i) => (
                  <Box
                    key={`${transaction.order_id}-${i}`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={`4px solid ${colors.primary[500]}`}
                    p="15px"
                  >
                    <Box>
                      <Typography
                        color={colors.greenAccent[500]}
                        variant="h5"
                        fontWeight="600"
                      >
                        {transaction.order_id}
                      </Typography>
                      <Typography color={colors.grey[100]}>
                        {transaction.description}
                      </Typography>
                    </Box>
                    <Box color={colors.grey[100]}>{transaction.weight}</Box>
                    <Box
                      backgroundColor={colors.redAccent[500]}
                      p="5px 10px"
                      borderRadius="4px"
                      color="white"
                    >
                      ₹{transaction.wages}
                    </Box>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader title={"C U S T O M E R - G R O U P"} />
              <CardContent>
                <Box height="270px" mt="-20px">
                  <CustomerGroupBarChart isDashboard={true} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{`Todays Gold Rate - ${new Date()}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Gold Rate(gms)"
            fullWidth
            required
            name="gold_rate_per_gm"
            value={newGoldRate.gold_rate_per_gm}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorGold}</span>
          <TextField
            margin="dense"
            label="Silver Rate(gms)"
            fullWidth
            required
            name="silver_rate_per_gm"
            value={newGoldRate.silver_rate_per_gm}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorSilver}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleAddGoldRate}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModalGold} onClose={handleCloseModalGold}>
        <DialogTitle>Gold Rate Details</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Gold Rate (per gm)</TableCell>
                  <TableCell>Silver Rate (per gm)</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.gold_rate_per_gm}</TableCell>
                    <TableCell>{item.silver_rate_per_gm}</TableCell>
                    <TableCell>{formatDate(item.created_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalGold}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* </Box> */}
    </Container>
  );
};

export default Dashboard;
