// import { ResponsiveLine } from "@nivo/line";
// import { useTheme } from "@mui/material";
// import { tokens } from "../theme";
// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     const fetchMapData = async () => {
//       try {
//         const response = await axios.get(
//           `https://skv-testing.moiaccount.in/testapi/orders/getOrders`
//         );
//         const transformedData = transformData(response.data.mData);
//         setChartData(transformedData);
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchMapData();
//   }, []);

//   const transformData = (data) => {
//     const series = [
//       {
//         id: "Orders",
//         color: tokens("dark").greenAccent[500],
//         data: data.map((item) => ({
//           x: item.customer_id,
//           y: parseFloat(item.weight),
//           contact_person_name: item.contact_person_name, // Include contact_person_name in the data
//         })),
//       },
//     ];
//     return series;
//   };

//   return (
//     <ResponsiveLine
//       data={chartData}
//       theme={{
//         axis: {
//           domain: {
//             line: {
//               stroke: colors.grey[100],
//             },
//           },
//           legend: {
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//           ticks: {
//             line: {
//               stroke: colors.grey[100],
//               strokeWidth: 1,
//             },
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//         },
//         legends: {
//           text: {
//             fill: colors.grey[100],
//           },
//         },
//         tooltip: {
//           container: {
//             color: colors.primary[500],
//           },
//         },
//       }}
//       colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
//       margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
//       xScale={{ type: "point" }}  // Change this to "point" or "ordinal" if IDs are not numeric
//       yScale={{
//         type: "linear",
//         min: 25, // updated to start y-axis from 25
//         max: "auto",
//         stacked: true,
//         reverse: false,
//       }}
//       yFormat=" >-.2f"
//       curve="catmullRom"
//       axisTop={null}
//       axisRight={null}
//       axisBottom={{
//         orient: "bottom",
//         tickSize: 0,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: isDashboard ? undefined : "Customer ID",
//         legendOffset: 36,
//         legendPosition: "middle",
//       }}
//       axisLeft={{
//         orient: "left",
//         tickValues: 5,
//         tickSize: 3,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: isDashboard ? undefined : "Weight",
//         legendOffset: -40,
//         legendPosition: "middle",
//       }}
//       enableGridX={false}
//       enableGridY={false}
//       pointSize={8}
//       pointColor={{ theme: "background" }}
//       pointBorderWidth={2}
//       pointBorderColor={{ from: "serieColor" }}
//       pointLabelYOffset={-12}
//       useMesh={true}
//       tooltip={({ point }) => (
//         <div
//           style={{
//             background: 'white',
//             padding: '9px 12px',
//             border: '1px solid #ccc',
//           }}
//         >
//           <strong>{point.data.x}</strong><br />
//           {`Weight: ${point.data.y}`}<br />
//           {`Contact: ${point.data.contact_person_name}`}
//         </div>
//       )}
//       legends={[
//         {
//           anchor: "bottom-right",
//           direction: "column",
//           justify: false,
//           translateX: 100,
//           translateY: 0,
//           itemsSpacing: 0,
//           itemDirection: "left-to-right",
//           itemWidth: 80,
//           itemHeight: 20,
//           itemOpacity: 0.75,
//           symbolSize: 12,
//           symbolShape: "circle",
//           symbolBorderColor: "rgba(0, 0, 0, .5)",
//           effects: [
//             {
//               on: "hover",
//               style: {
//                 itemBackground: "rgba(0, 0, 0, .03)",
//                 itemOpacity: 1,
//               },
//             },
//           ],
//         },
//       ]}
//     />
//   );
// };

// export default LineChart;

//----------------------------------------------------------------------------------

// import { ResponsiveLine } from "@nivo/line";
// import { useTheme } from "@mui/material";
// import { tokens } from "../theme";
// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const LineChart = ({
//   year,
//   month,
//   isCustomLineColors = false,
//   isDashboard = false,
// }) => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     const fetchMapData = async () => {
//       try {
//         // const response = await axios.get(
//         //   `https://skv-testing.moiaccount.in/testapi/orders/getOrders`, {
//         //     params: { year, month }
//         //   }
//         // );
//         const response = await axios.post(
//           `https://skv-testing.moiaccount.in/testapi/chartData/getOrdersForChart`,
//           {
//             year: year,
//             month: month,
//           }
//         );
//         console.log("resp",response)
//         const transformedData = transformData(response.data.mData);
//         setChartData(transformedData);
//       } catch (error) {
//         console.log(error);
//       }
//     };

//     fetchMapData();
//   }, [year, month]);

//   const transformData = (data) => {
//     const series = [
//       {
//         id: "Orders",
//         color: tokens("dark").greenAccent[500],
//         data: data.map((item) => ({
//           // x: item.customer_id,
//           // y: parseFloat(item.weight),
//           // contact_person_name: item.contact_person_name, // Include contact_person_name in the data
//           x:item.order_date,
//           y:item.order_count,
//         })),
//       },
//     ];
//     return series;
//   };

//   return (
//     <ResponsiveLine
//       data={chartData}
//       theme={{
//         axis: {
//           domain: {
//             line: {
//               stroke: colors.grey[100],
//             },
//           },
//           legend: {
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//           ticks: {
//             line: {
//               stroke: colors.grey[100],
//               strokeWidth: 1,
//             },
//             text: {
//               fill: colors.grey[100],
//             },
//           },
//         },
//         legends: {
//           text: {
//             fill: colors.grey[100],
//           },
//         },
//         tooltip: {
//           container: {
//             color: colors.primary[500],
//           },
//         },
//       }}
//       colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
//       margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
//       xScale={{ type: "point" }} // Change this to "point" or "ordinal" if IDs are not numeric
//       yScale={{
//         type: "linear",
//         min: 25, // updated to start y-axis from 25
//         max: "auto",
//         stacked: true,
//         reverse: false,
//       }}
//       yFormat=" >-.2f"
//       curve="catmullRom"
//       axisTop={null}
//       axisRight={null}
//       axisBottom={{
//         orient: "bottom",
//         tickSize: 0,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: isDashboard ? undefined : "Customer ID",
//         legendOffset: 36,
//         legendPosition: "middle",
//       }}
//       axisLeft={{
//         orient: "left",
//         tickValues: 5,
//         tickSize: 3,
//         tickPadding: 5,
//         tickRotation: 0,
//         legend: isDashboard ? undefined : "Weight",
//         legendOffset: -40,
//         legendPosition: "middle",
//       }}
//       enableGridX={false}
//       enableGridY={false}
//       pointSize={8}
//       pointColor={{ theme: "background" }}
//       pointBorderWidth={2}
//       pointBorderColor={{ from: "serieColor" }}
//       pointLabelYOffset={-12}
//       useMesh={true}
//       tooltip={({ point }) => (
//         <div
//           style={{
//             background: "white",
//             padding: "9px 12px",
//             border: "1px solid #ccc",
//           }}
//         >
//           <strong>{point.data.x}</strong>
//           <br />
//           {`Weight: ${point.data.y}`}
//           <br />
//           {`Contact: ${point.data.contact_person_name}`}
//         </div>
//       )}
//       legends={[
//         {
//           anchor: "bottom-right",
//           direction: "column",
//           justify: false,
//           translateX: 100,
//           translateY: 0,
//           itemsSpacing: 0,
//           itemDirection: "left-to-right",
//           itemWidth: 80,
//           itemHeight: 20,
//           itemOpacity: 0.75,
//           symbolSize: 12,
//           symbolShape: "circle",
//           symbolBorderColor: "rgba(0, 0, 0, .5)",
//           effects: [
//             {
//               on: "hover",
//               style: {
//                 itemBackground: "rgba(0, 0, 0, .03)",
//                 itemOpacity: 1,
//               },
//             },
//           ],
//         },
//       ]}
//     />
//   );
// };

// export default LineChart;

//-----------------------------------------------------------------------

import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import React, { useEffect, useState } from "react";
import axios from "axios";

const LineChart = ({
  year,
  month,
  isCustomLineColors = false,
  isDashboard = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const response = await axios.post(
          `https://skv-testing.moiaccount.in/testapi/chartData/getOrdersForChart`,
          {
            year: year,
            month: month,
          }
        );
        const transformedData = transformData(response.data.mData);
        setChartData(transformedData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMapData();
  }, [year, month]);

  const dateParser = (date) => {
    const firstHalf = date.split("-")[2];
    return firstHalf;
  };

  // console.log(dateParser('2024-07-23'));
  const transformData = (data) => {
    return data.map((item) => ({
      date: item.month || dateParser(item.order_date),
      orders: item.order_count,
    }));
  };

  return (
    <ResponsiveBar
      data={chartData}
      keys={["orders"]}
      indexBy="date"
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Order Date",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "Order Count",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      tooltip={({ id, value, color, indexValue }) => (
        <div
          style={{
            background: "white",
            padding: "9px 12px",
            border: "1px solid #ccc",
          }}
        >
          <strong>{indexValue}</strong>
          <br />
          {`${id}: ${value}`}
        </div>
      )}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineChart;
