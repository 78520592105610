import { decryptData } from "./encryption";
import { selectTheCustomerAlert } from "./sweetAlert";

/* eslint-disable eqeqeq */
const currentDate = () => {
  let currentDate = new Date();

  // Get the current day, month, and year
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear();

  let formattedDate = day + "-" + month + "-" + year;
  return formattedDate;
};

/** Fetching Current User In Session */
const userName = () => {
  return decryptData(sessionStorage.getItem("LoggedInUserName"));
};

/** Generate Receipt */
export const generateReceipt = (group, filterRows) => {
  // Check if there's any data for the selected customer
  if (filterRows().length === 0) {
    console.error("No data available for the selected customer");
    return;
  }

  // Generate the receipt content
  const receiptContent = filterRows().map((item) => {
    return {
      order_id: item.order_id,
      product: item.description,
      weight: item.weight,
      customer_name: item.contact_person_name,
      order_status: item.order_status == 0 ? "Pending" : "Closed",
      purity: item.purity,
      orderDate: item.order_date,
    };
  });

  const totalWeight = receiptContent
    .reduce((total, item) => total + parseFloat(item.weight), 0)
    .toFixed(3);

  // Create a new window for printing
  const printWindow = window.open("", "_blank");
  printWindow.document.write(`
    <html>
      <head>
      <title></title>
        <style>
          /* Add your custom styles for the receipt here */
          body {
            font-family: Arial, sans-serif;
            font-size : 8px
           
          }
          table {
            border-collapse: collapse;
            width: 80%;
            font-size : 8px
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          td:nth-child(1){
            width : 7%,
            display : flex,
            flex-wrap : nowrap,
          }
          td:nth-child(2){
            width : 10%
          }
          td:nth-child(3){
            width : 5%
          }
          button {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #4caf50;
            color: white;
            border: none;
            cursor: pointer;
          }
          button:hover {
            background-color: #45a049;
          }
          h3,h4,p,h1{
            text-align:center;
          }
          .spacing{
            margin : 1px
          }
        </style>
      </head>
      <body>
      <p style="text-align:center">உ</p>
      <h3 class="spacing">SKV Silvers</h3>
      <p class="spacing">+91 6383887488, Ramanathapuram</p>
      <h3 style="margin-top : 3px">${group} Orders</h3>
      <div style="display : flex; justify-content : space-between ; margin-top : -20px">
      <h5>Date: ${currentDate()}</h5>
      <h5>Receipted By: ${userName()}</h5>
      </div>
        <table>
            <tr>
            <th>Order ID</th>
             <th>Customer Name</th>
             <th>Details</th>
              <th>Order Date</th>
              <th>Weight</th>
              <th>Order Status</th>
            </tr>
          <tbody>
            ${receiptContent
              .map(
                (item) => `
              <tr>
              <td>${item.order_id}</td>
                <td>${item.customer_name}</td>
                <td>${item.product}</td>
                <td>${item.orderDate}</td>
                <td>${item.weight}</td>
                <td>${item.order_status}</td>
              </tr>
            `
              )
              .join("")}
              <tr>
              <td colspan="5" style="text-align: right;">Total Weight:</td>
              <td>${totalWeight}</td>
            </tr> 
            <tr>
            <td colspan="5" style="text-align: right;">OB:</td>
            <td></td>
          </tr> 
          </tbody>
        </table>
        <h3>Thank You</h3>
      </body>
    </html>
  `);
  // Call the print function after the document is written
  printWindow.document.close();
  printWindow.print();

  // Call the ownerReceipt function immediatel

  // Close the window if it's not null
  if (printWindow) {
    printWindow.close();
  }
};

/** Generate Summary Receipt */
export const generateSummary = (filteredCustomerOrderedData) => {
  if (filteredCustomerOrderedData.length === 0) {
    /** Alert For Select The Customer */
    return selectTheCustomerAlert();
  }
  let totalWeight = 0;
  let customerName = filteredCustomerOrderedData[0].contact_person_name || "";
  const summaryData = filteredCustomerOrderedData.reduce((acc, item) => {
    if (item.is_delete === 0) {
      const product = item.description.trim();
      if (!acc[product]) {
        acc[product] = 0;
      }

      acc[product] += parseFloat(item.weight);
      totalWeight = totalWeight + parseFloat(item.weight);
    }
    return acc;
  }, {});

  // Create a new window for printing the summary
  const printWindow = window.open("", "_blank");
  printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 15px;
            }
            table {
              border-collapse: collapse;
              width: 70%;
              font-size: 15px;
            }
            th, td {
              border: 1px solid black;
              padding: 5px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
            h3, h4, p, h1 {
              text-align: center;
            }
          </style>
        </head>
        <body>
           <p>உ</p>
          <h3>${customerName}</h3>
          <div style="display: flex; justify-content: space-between;">
            <h5>Date: ${currentDate()}</h5>
          </div>
          <table>
            <tr>
              <th>Description</th>
              <th>Total Weight</th>
            </tr>
            <tbody>
              ${Object.keys(summaryData)
                .map(
                  (product) => `
                <tr>
                  <td>${product}</td>
                  <td>${summaryData[product].toFixed(3)}</td>
                </tr>
              `
                )
                .join("")}
               
            </tbody>
          </table>
           <h4>Total Summary Weight : ${totalWeight.toFixed(2)}</h4>
          <h3>Thank You</h3>
        </body>
      </html>
    `);
  printWindow.document.close();
  printWindow.print();
};

// export const generateDateReceiptSummary = (
//   customerOptions,
//   filteredCustomerOrderedData
// ) => {
//   // Group orders by customer_id and product description
//   const groupedByCustomerAndProduct = filteredCustomerOrderedData.reduce(
//     (acc, order) => {
//       if (!acc[order.customer_id]) {
//         acc[order.customer_id] = {};
//       }
//       if (!acc[order.customer_id][order.description]) {
//         acc[order.customer_id][order.description] = 0;
//       }
//       acc[order.customer_id][order.description] += parseFloat(order.weight);
//       return acc;
//     },
//     {}
//   );

//   const receiptContent = Object.entries(groupedByCustomerAndProduct)
//     .map(([customerId, products]) => {
//       const customerName =
//         customerOptions.find((customer) => customer.customer_id === customerId)
//           ?.contact_person_name || "";

//       const productEntries = Object.entries(products)
//         .map(([productName, totalWeight]) => {
//           return `
//         <tr>
//           <td>${productName}</td>
//           <td>${customerName}</td>
//           <td>${totalWeight.toFixed(3)}</td>
//         </tr>
//       `;
//         })
//         .join("");

//       return `
//       <h3>${customerName}</h3>
//       <table>
//         <tr>
//           <th>Description</th>
//           <th>Customer Name</th>
//           <th>Total Weight</th>
//         </tr>
//         ${productEntries}
//       </table>
//       <h1>----✂---------------------✂----------------------✂---------------------------✂---------------------------</h1>
//     `;
//     })
//     .join("");

//   // Create a new window for printing
//   const printWindow = window.open("", "_blank");
//   printWindow.document.write(`
//     <html>
//       <head>
//         <title>Print Receipt</title>
//         <style>
//           body {
//             font-family: Arial, sans-serif;
//             font-size: 10px;
//             margin-top: 1%;
//           }
//           table {
//             border-collapse: collapse;
//             width: 60%;
//             font-size: 10px;
//           }
//           th, td {
//             border: 1px solid black;
//             padding: 8px;
//             text-align: left;
//           }
//           th {
//             background-color: #f2f2f2;
//           }
//           button {
//             margin-top: 20px;
//             padding: 10px 20px;
//             background-color: #4caf50;
//             color: white;
//             border: none;
//             cursor: pointer;
//           }
//           button:hover {
//             background-color: #45a049;
//           }
//           h3, h4, p, h1 {
//             text-align: center;
//             font-size: 10px;
//           }
//           .spacing {
//             margin: 1px;
//           }
//         </style>
//       </head>
//       <body>
//         <p style="text-align: center; font-size: 10px">உ</p>
//         <div style="display: flex; justify-content: space-between; margin-top: -20px">
//           <h5 style="font-size: 10px">Date: ${currentDate()}</h5>
//         </div>
//         ${receiptContent}
//         <h3>Thank You</h3>
//         <h3>SKV</h3>
//       </body>
//     </html>
//   `);
//   printWindow.document.close();
//   printWindow.print();

//   if (printWindow) {
//     printWindow.close();
//   }
// };

export const generateDateReceiptSummary = (
  customerOptions,
  filteredCustomerOrderedData
) => {
  console.log("customerOptAtStart", customerOptions);
  // Group orders by customer_id and product description
  const groupedByCustomerAndProduct = filteredCustomerOrderedData.reduce(
    (acc, order) => {
      if (!acc[order.customer_id]) {
        acc[order.customer_id] = {};
      }
      if (!acc[order.customer_id][order.description]) {
        acc[order.customer_id][order.description] = 0;
      }
      acc[order.customer_id][order.description] += parseFloat(order.weight);
      return acc;
    },
    {}
  );

  const receiptContent = Object.entries(groupedByCustomerAndProduct)
    .map(([customerId, products]) => {
      console.log("customerOpt", customerOptions);
      const customerName =
        customerOptions.find((customer) => customer.customer_id === customerId)
          ?.contact_person_name || "";

      const productEntries = Object.entries(products)
        .map(([productName, totalWeight]) => {
          return `
        <tr>
          <td>${productName}</td>
          <td>${customerName}</td>
          <td>${totalWeight.toFixed(3)}</td>
        </tr>
      `;
        })
        .join("");

      return `
      <table>
        <tr>
          <th>Description</th>
          <th>Customer Name</th>
          <th>Total Weight</th>
        </tr>
        ${productEntries}
      </table>
      <h1>----✂---------------------✂----------------------✂---------------------------✂---------------------------</h1>
    `;
    })
    .join("");

  // Create a new window for printing
  const printWindow = window.open("", "_blank");
  printWindow.document.write(`
    <html>
      <head>
        <title>Print Receipt</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            font-size: 10px;
            margin-top: 1%;
          }
          table {
            border-collapse: collapse;
            width: 60%;
            font-size: 10px;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          button {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #4caf50;
            color: white;
            border: none;
            cursor: pointer;
          }
          button:hover {
            background-color: #45a049;
          }
          h3, h4, p, h1 {
            text-align: center;
            font-size: 10px;
          }
          .spacing {
            margin: 1px;
          }
        </style>
      </head>
      <body>
        <p style="text-align: center; font-size: 10px">உ</p>
        <div style="display: flex; justify-content: space-between; margin-top: -20px">
          <h5 style="font-size: 10px">Date: ${currentDate()}</h5>
        </div>
        ${receiptContent}
        <h3>Thank You</h3>
        <h3>SKV</h3>
      </body>
    </html>
  `);
  printWindow.document.close();
  printWindow.print();

  if (printWindow) {
    printWindow.close();
  }
};
