import axios from "axios";

// Function to fetch customer data
export const fetchCustomerOptionData = async (group, setCustomerOptions) => {
  try {
    const response = await axios.get(
      `https://skv-testing.moiaccount.in/testapi/customers/getCustomer`
      //`https://skv-testing.moiaccount.in/testapi/customers/getCustomer`
    );
    if (response.data.mData) {
      const activeCustomers = response.data.mData.filter(
        (data) => data.is_delete !== 1
      );

      let skvCustomers;
      // eslint-disable-next-line default-case
      switch (group) {
        case "skvOrders":
          skvCustomers = activeCustomers.filter(
            (data) => data.purity === "SKV/5" || data.purity === "SKV?80"
          );
          break;
        case "skv80":
          skvCustomers = activeCustomers.filter(
            (data) => data.purity === "SKV?80"
          );
          break;
        case "skv5":
          skvCustomers = activeCustomers.filter(
            (data) => data.purity === "SKV/5"
          );
      }
      //setCustomerOptions(response.data.mData);
      setCustomerOptions(skvCustomers);
    }
  } catch (error) {
    console.log(error.message);
  }
};

export const options = [
  { label: "METTI(M)", value: "M" },
  { label: "KAAPU(KA)", value: "KA" },
  { label: "KODI(K)", value: "K" },
  { label: "70", value: "70" },
  { label: "70-Kolusu", value: "70-Kolusu" },
  { label: "80", value: "80" },
  { label: "80-Kolusu", value: "80-Kolusu" },
  { label: "THANDAI(TH)", value: "T" },
  { label: "NT", value: "NT" },
  { label: "WS", value: "WS" },
  { label: "BY", value: "BY" },
  { label: "Other", value: "other" },
];
