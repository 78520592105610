import * as React from "react";
import { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Grid,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { options } from "../support/customerData";
import {
  invalidDateFormatAlert,
  limitExceedsAlert,
} from "../support/sweetAlert";

const BulkOrder = () => {
  const [bulkOrders, setBulkOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [totalWeight, setTotalWeight] = useState(0);
  const inputRefs = useRef(new Map()); // Use a Map to store refs

  const navigate = useNavigate();
  const { state } = useLocation();
  const customerOptions =
    (state && state.customerOptions && state["customerOptions"]) || {};

  const calculateTotalWeight = (orders) => {
    return orders.reduce(
      (total, order) => total + parseFloat(order.weight || 0),
      0
    );
  };

  const handleAddOrder = () => {
    if (bulkOrders.length < 20) {
      const newOrders = [
        ...bulkOrders,
        {
          customer_id: "",
          description: "",
          weight: "",
          quantity: "",
          wages: "",
        },
      ];
      setBulkOrders(newOrders);
      setTotalWeight(calculateTotalWeight(newOrders));
    } else {
      /** Alert For Limit Exceeds */
      limitExceedsAlert();
    }
  };

  const handleRemoveOrder = (index) => {
    const newOrders = bulkOrders.filter((order, i) => i !== index);
    setBulkOrders(newOrders);
    setTotalWeight(calculateTotalWeight(newOrders));
  };

  const handleOrderChange = (index, field, value) => {
    const newOrders = [...bulkOrders];
    newOrders[index][field] = value;
    setBulkOrders(newOrders);
    setTotalWeight(calculateTotalWeight(newOrders));
  };

  const checkForDuplicates = () => {
    const duplicates = bulkOrders.filter((order, index) => {
      return (
        bulkOrders.findIndex(
          (item, i) =>
            item.customer_id === order.customer_id &&
            item.description === order.description &&
            item.weight === order.weight &&
            item.quantity === order.quantity &&
            item.wages === order.wages &&
            i !== index
        ) !== -1
      );
    });
    return duplicates.length > 0;
  };

  const validateDate = (date) => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD format
    return datePattern.test(date);
  };

  const handleBulkUpload = async () => {
    if (!selectedDate || !validateDate(selectedDate)) {
      /** Invalid Date Alert */
      invalidDateFormatAlert();
      return;
    }

    if (checkForDuplicates()) {
      Swal.fire({
        title: "Duplicate Entries",
        text: "There are duplicate entries. Do you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, upload",
        cancelButtonText: "No, go back",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await uploadOrders();
        }
      });
    } else {
      await uploadOrders();
    }
  };

  const uploadOrders = async () => {
    const ordersToUpload = bulkOrders.map((order) => ({
      ...order,
      order_date: selectedDate,
      inserted_by: parseInt(sessionStorage.getItem("userId")),
      order_status: 0,
      is_reviewed: sessionStorage.getItem("userType") === "1" ? 1 : 0,
    }));

    try {
      const response = await axios.post(
        `https://skv-testing.moiaccount.in/testapi/orders/addOrders`,
        ordersToUpload
      );
      if (response.data.mStatus === 200) {
        Swal.fire("Success", "Orders uploaded successfully", "success");
        navigate("/team");
      } else {
        Swal.fire("Error", "Failed to upload orders", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Failed to upload orders", "error");
    }
  };

  const handleArrowRight = (index) => (event) => {
    if (event.key === "ArrowRight") {
      event.preventDefault();
      const nextIndex = index + 1;
      if (nextIndex < bulkOrders.length) {
        const nextInputRef = inputRefs.current.get(nextIndex);
        if (nextInputRef) {
          nextInputRef.focus();
        }
      }
    }
  };

  return (
    <Box sx={{ margin: 3, height: "100vh", overflow: "auto" }}>
      <Box
        sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}
      >
        <Header
          title="Bulk Order Entry"
          subtitle="Manage The Multi Order Registration"
        />
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          Order Date
        </Typography>
        <TextField
          type="date"
          variant="outlined"
          fullWidth
          required
          value={selectedDate}
          onChange={(event) => setSelectedDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mb: 2 }}
        />
      </Box>
      <DialogContent>
        {bulkOrders.map((order, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
            <Grid item xs={12} md={2}>
              <Typography variant="subtitle1">Order {index + 1}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={customerOptions}
                getOptionLabel={(option) => option.contact_person_name}
                onChange={(event, newValue) =>
                  handleOrderChange(
                    index,
                    "customer_id",
                    newValue ? newValue.customer_id : ""
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer Name"
                    fullWidth
                    inputRef={(el) => inputRefs.current.set(index, el)}
                    onKeyDown={handleArrowRight(index)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) =>
                  handleOrderChange(
                    index,
                    "description",
                    newValue ? newValue.value : ""
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Description"
                    fullWidth
                    inputRef={(el) => inputRefs.current.set(index, el)}
                    onKeyDown={handleArrowRight(index)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                label="Weight"
                value={order.weight}
                onChange={(event) =>
                  handleOrderChange(index, "weight", event.target.value)
                }
                fullWidth
                inputRef={(el) => inputRefs.current.set(index, el)}
                onKeyDown={handleArrowRight(index)}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                label="Quantity"
                value={order.quantity}
                onChange={(event) =>
                  handleOrderChange(index, "quantity", event.target.value)
                }
                fullWidth
                inputRef={(el) => inputRefs.current.set(index, el)}
                onKeyDown={handleArrowRight(index)}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextField
                label="Wages"
                value={order.wages}
                onChange={(event) =>
                  handleOrderChange(index, "wages", event.target.value)
                }
                fullWidth
                inputRef={(el) => inputRefs.current.set(index, el)}
                onKeyDown={handleArrowRight(index)}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <IconButton
                onClick={() => handleRemoveOrder(index)}
                sx={{ color: "red", mt: 2 }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          onClick={handleAddOrder}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ mt: 2 }}
        >
          Add Order
        </Button>
        <Button
          onClick={handleBulkUpload}
          variant="contained"
          color="primary"
          sx={{ mt: 2, ml: 2 }}
        >
          Upload Orders
        </Button>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Total Weight: {totalWeight} kg
        </Typography>
      </DialogContent>
    </Box>
  );
};

export default BulkOrder;
