/* eslint-disable eqeqeq */
import axios from "axios";

export const fetchOrdersData = async (setRows) => {
  try {
    const response = await axios.get(
      `https://skv-testing.moiaccount.in/testapi/orders/getOrders`
      //`https://skv-testing.moiaccount.in/testapi/orders/getOrders`
    );
    let filteredRows;
    if (response.data.mData) {
      if (sessionStorage.getItem("userType") == 1) {
        filteredRows = response.data.mData.filter((row) => row.is_delete !== 1);
        // return filteredRows;
        setRows(filteredRows);
      } else {
        filteredRows = response.data.mData.filter(
          (row) =>
            row.is_delete !== 1 && row.is_reviewed != 1 && row.order_status == 0
        );

        // return filteredRows;
        setRows(filteredRows);
      }
    }
    localStorage.setItem("lenOfOrders", filteredRows.length);
  } catch (error) {
    console.log(error);
  }
};

export const fetchSKV5OrdersData = async (setRows, orders) => {
  try {
    const response = await axios.get(
      `https://skv-testing.moiaccount.in/testapi/orders/getOrders`
      //`https://skv-testing.moiaccount.in/testapi/orders/getOrders`
    );
    if (response.data.mData) {
      let filteredRows;
      if (sessionStorage.getItem("userType") == 1) {
        filteredRows = response.data.mData.filter((row) => row.is_delete !== 1);
      }
      if (sessionStorage.getItem("userType") != 1) {
        filteredRows = response.data.mData.filter(
          (row) =>
            row.is_delete !== 1 && row.is_reviewed != 1 && row.order_status == 0
        );
      }

      if (orders === "closed") {
        const skvOrders = filteredRows.filter((row) => row.purity === "SKV/5");
        const skv5ClosedOrder = skvOrders.filter(
          (item) => item.order_status == "2"
        );
        setRows(skv5ClosedOrder);
        localStorage.setItem("skv5Orders", skvOrders.length);
      }
      if (orders === "pending") {
        const skvOrders = filteredRows.filter((row) => row.purity === "SKV/5");
        const skv5PendingOrder = skvOrders.filter(
          (item) => item.order_status != "2"
        );
        setRows(skv5PendingOrder);
        localStorage.setItem("skv5Orders", skvOrders.length);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchSKV80OrdersData = async (setRows, orders) => {
  try {
    const response = await axios.get(
      `https://skv-testing.moiaccount.in/testapi/orders/getOrders`
      //`https://skv-testing.moiaccount.in/testapi/orders/getOrders`
    );
    if (response.data.mData) {
      let filteredRows;
      if (sessionStorage.getItem("userType") == 1) {
        filteredRows = response.data.mData.filter((row) => row.is_delete !== 1);
      } else {
        filteredRows = response.data.mData.filter(
          (row) =>
            row.is_delete !== 1 && row.is_reviewed != 1 && row.order_status == 0
        );
      }

      // eslint-disable-next-line default-case
      switch (orders) {
        case "closed":
          const skvOrders = filteredRows.filter(
            // eslint-disable-next-line eqeqeq
            (row) => row.purity == "SKV?80"
          );
          const skv80ClosedOrders = skvOrders.filter(
            // eslint-disable-next-line eqeqeq
            (item) => item.order_status == "2"
          );
          setRows(skv80ClosedOrders);
          localStorage.setItem("skv80Orders", skvOrders.length);
          break;
        case "pending":
          const skvOrdersClosed = filteredRows.filter(
            // eslint-disable-next-line eqeqeq
            (row) => row.purity == "SKV?80"
          );
          const skv80ClosedOrder = skvOrdersClosed.filter(
            // eslint-disable-next-line eqeqeq
            (item) => item.order_status != "2"
          );
          setRows(skv80ClosedOrder);
          localStorage.setItem("skv80Orders", skvOrders.length);
          break;
      }
    }
  } catch (error) {
    console.log(error.message);
  }
};
