import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import OrderRegPage from "./scenes/pages/OrderRegPage";
import UserRegPage from "./scenes/pages/UserRegPage";
import CustomerRegPage from "./scenes/pages/CustomerRegPage";
import PrintReceiptPage from "./scenes/pages/PrintReceiptPage";
import GstOrderRegPage from "./scenes/pages/GstOrderRegPage";
import Skv5Orders from "./scenes/pages/SKV5OrdersPage";
import Skv80Orders from "./scenes/pages/SKV80OrdersPage";
import Form from "./scenes/form";
import Bar from "./scenes/bar";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Calendar from "./scenes/calendar/calendar";
import Geography from "./scenes/geography";
import SignIn from "./scenes/form/Login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PasswordReset from "./scenes/form/PasswordReset";
import Skv5ClosedOrders from "./scenes/pages/SKV5ClosedOrders";
import Skv80ClosedOrders from "./scenes/pages/SKV80ClosedOrders";
import BulkOrder from "./scenes/DataGrid/BulkOrder";
import DateReceiptPage from "./scenes/pages/DateReceiptPage";
import { decryptData } from "./scenes/support/encryption";
import OverviewModal from "./scenes/DataGrid/OverviewModal";
import OverViewPage from "./scenes/pages/OverViewPage";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if the user is already authenticated from localStorage
    const loggedInPhn = decryptData(sessionStorage.getItem("LoggedInPhn"));
    if (loggedInPhn) {
      setIsAuthenticated(true);
      // Assuming that admin user can be identified by a specific role or a flag in localStorage
      const userType = sessionStorage.getItem("userType");
      if (userType === "Admin") {
        setIsAdmin(true);
      }
    }
  }, []);

  const handleLogin = (phoneNumber) => {
    setIsAuthenticated(true);
    const userType = sessionStorage.getItem("userType");
    if (userType === "Admin") {
      setIsAdmin(true);
    }
    sessionStorage.setItem("currentUser", phoneNumber);
  };

  const handleSignOut = () => {
    setIsAuthenticated(false);
    setIsAdmin(false);
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("LoggedInPhn");
    sessionStorage.removeItem("LoggedInPassword");
    sessionStorage.removeItem("LoggedInUserName");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("userType");
    sessionStorage.removeItem("userId");
  };

  if (!isAuthenticated) {
    return <SignIn handleLogin={handleLogin} />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} onSignOut={handleSignOut} />
            <Routes>
              <Route path="/dash" element={<Dashboard />} />
              <Route path="/team" element={<OrderRegPage />} />
              <Route path="/bulk-order" element={<BulkOrder />} />
              <Route path="/overView" element={<OverViewPage />} />
              <Route path="/contacts" element={<UserRegPage />} />
              <Route path="/custReg" element={<CustomerRegPage />} />
              <Route path="/printReceipt" element={<PrintReceiptPage />} />
              <Route path="/dateReceipt" element={<DateReceiptPage />} />
              <Route path="/gstOrderReg" element={<GstOrderRegPage />} />
              <Route path="/skv-5" element={<Skv5Orders />} />
              <Route path="/skv5-closed" element={<Skv5ClosedOrders />} />
              <Route path="/skv-80" element={<Skv80Orders />} />
              <Route path="/skv-80-closed" element={<Skv80ClosedOrders />} />
              <Route path="/form" element={<Form />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="*" element={<Navigate to="/dash" replace />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

//---------------------------------------------------------------------------------------------
